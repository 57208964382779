@import './globals.scss';

.full-width {
  width: 100%;
}

.pading-none {
  padding: 0;
}

.margin-none {
  margin: 0;
}

.std-padding {
  padding: 24px;
}

.std-margin {
  margin: 24px;
}

.std-margin-vertical {
  margin: 24px 0;
}

.std-margin-vertical__minus {
  margin: -24px 0;
}

.std-margin-horizontal {
  margin: 0 24px;
}

.std-margin-horizontal__minus {
  margin: 0 -24px;
}

.std-margin-bottom {
  margin-bottom: 24px;
}

.std-margin-top {
  margin-top: 20px;
}

.std-margin-left {
  margin-left: 24px;
}

.std-margin-right {
  margin-right: 24px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-gray {
  background: #eee;
}

.d-flex {
  display: flex;
}

.pos-relative {
  position: relative;
}

.button-default-table {
  position: absolute;
  left: 0;
  bottom: 16px;
}

.ant-form-item {
  margin-bottom: 5px;
}
.ant-page-header {
  padding: 0px 0px 16px 0px;
}

.ant-form-item-control {
  padding-right: 10px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 16px;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn
{
  display: none;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-cell-value-wrap:empty:before {
  content: '\00a0\00a0'
}

.editable-cell-value-empty {
  padding: 5px 12px;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.editable-table .ant-input, .editable-table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0
}

li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-disabled.ant-menu-item-env-label,
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-disabled.ant-menu-item-env-label > span > a
{
  color: red !important;
}
