@import './globals.scss';

.print-page {
  width: 100%;
  line-height: 0.99em;
  font-family: "Times New Roman", serif;
  font-size: 12px;
  border-radius: 5px;
  text-align: justify;
}

.print-page, .print-page .ant-typography {
  font-size: 12px;
}

.print-table, .print-table .ant-typography, .print-table .ant-table-content, .print-table .ant-table-container,
.print-table .ant-table, .print-table .ant-spin-container, .print-table .ant-spin-nested-loading {
  page-break-inside: auto;
  font-size: 12px;
}

.print-table .ant-table table {
  border-collapse: collapse;
  page-break-inside: auto;
}

.print-table .ant-table-thead > tr > th {
  border: #111111 1px solid;
  background: none;
  border-collapse: collapse;
  page-break-inside: avoid;
  font-size: 12px;
}

.print-table .ant-table-tbody > tr > td {
  border: #111111 1px solid;
  background: none;
  border-collapse: collapse;
  font-size: 12px;
}

.print-table-contract .ant-table-tbody > tr > td {
  border: #111111 1px solid;
  background: none;
  border-collapse: collapse;
  font-size: 12px;
  padding: 1px 1px;
  line-height: normal;
}

@media print {
  @page {
    size: auto;
    margin: 0.75in;
  }

  span {
    display: block;
    break-inside: avoid;
  }

  div {
    break-inside: avoid;
  }

  tr {
    break-inside: avoid;
  }

  body {
    background-color: #FFFFFF;
    border: 0;
  }
}

.page-not-break {
  page-break-inside: auto;
}

.page-break {
  display: block;
  page-break-before: always;
}