.site-layout-background {
  background: #fff;
}

.dashboard {
  &__inner {
    margin-top: 30px;
  }
}

.object__info {
  color: rgb(144, 144, 144);
  text-align: left;

  b {
    color: rgb(98, 98, 98);
    font-weight: 500;
  }
}

.object__links {
  display: flex;
  margin: 0 -15px;
  color: #111;
  margin-top: 20px;

  &_col {
    span {
      color: #1890ff;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
    }

    margin: 0 15px;
  }
}

.centerTable {
  .ant-table-cell {
    vertical-align: middle;
  }
}
