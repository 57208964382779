.spinner {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.submit {
  width: 100%;
}

.ant-card-head-title {
  text-align: left;
}

.ant-select-single .ant-select-selector {
  text-align: left;
}
